<template>

  <section class="section hero is-fullheight is-error-section">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-two-fifths">

            <card-component title="2FA Validation" icon="lock-open" :has-card-header-background="true">
              <form @submit.prevent="submit" method="POST">

                <b-field label="Validation code" message="Enter the code that was sent to your email address.">
                  <b-input type="string" name="code" v-model="form.code" required autofocus/>
                </b-field>

                <hr>

                <b-field grouped>
                  <b-button native-type="submit" type="is-primary" :loading="isLoading" expanded >Validate</b-button>
                </b-field>
              </form>
            </card-component>

          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot has-text-centered">
      <div class="logo"></div>
    </div>
  </section>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import auth from "@/store/modules/auth.store"
import settings from "@/store/modules/settings.store"

export default {
  name: 'Enter2FA',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      isSuccess: false,
      form: {
        email: null
      }
    }
  },
  created() {
    if(!auth.isRegistered) {
      this.$store.registerModule('auth', auth)
      auth.isRegistered = true
    }
    if(!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }

    // this.$store.commit('settings/asideRightToggle', false)
    // this.$store.commit('settings/asideMobileStateToggle', false)
    // this.$store.commit('settings/isNavbarVisible', false)


    this.$store.commit('settings/fullPage', true)
    this.$store.commit('settings/asideRightToggle', false)

  },
  beforeDestroy () {
    this.$store.commit('settings/fullPage', false)

  },

  methods: {
    submit () {
      this.isLoading = true

      this.$store.dispatch('validateTwofa', {
        code: this.form.code,
      })
          .then((res) => {
            this.isLoading = false

            if(res.data.success) {
              this.$buefy.snackbar.open({
                message: res.data.message,
                indefinite: false,
                type: "is-success",
                position: 'is-top'
              })
              this.$router.push('/')

            } else {
              this.$buefy.snackbar.open({
                message: res.data.message,
                indefinite: true,
                type: "is-danger",
                position: 'is-top'
              })

            }


          })
          .catch(error => {
            this.isLoading = false
            this.$buefy.snackbar.open(`error`)

          })

    }
  }
}
</script>
