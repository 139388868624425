<template>

  <section class="section hero is-fullheight is-error-section">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-two-fifths">

            <card-component title="Password Recovery" icon="lock-open" :has-card-header-background="true">
              <form @submit.prevent="submit" method="POST">

                <b-field label="E-mail Address" message="E-mail used for registration">
                  <b-input type="email" name="email" v-model="form.email" required autofocus/>
                </b-field>

                <hr>

                <b-field grouped>
                  <b-button native-type="submit" type="is-primary" :loading="isLoading" expanded >Send Reset Link</b-button>
                </b-field>
              </form>
            </card-component>
            <p class="has-text-white has-text-centered">
              <router-link to="/login">
                <a class="has-text-primary">Go Back</a>
              </router-link>

            </p>

          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot has-text-centered">
      <div class="logo"></div>
    </div>
  </section>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import auth from "@/store/modules/auth.store"
import settings from "@/store/modules/settings.store"

export default {
  name: 'Login',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      isSuccess: false,
      form: {
        email: null
      }
    }
  },
  created() {
    if(!auth.isRegistered) {
      this.$store.registerModule('auth', auth)
      auth.isRegistered = true
    }
    if(!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }

    // this.$store.commit('settings/asideRightToggle', false)
    // this.$store.commit('settings/asideMobileStateToggle', false)
    // this.$store.commit('settings/isNavbarVisible', false)


    this.$store.commit('settings/fullPage', true)
    this.$store.commit('settings/asideRightToggle', false)

  },
  beforeDestroy () {
    this.$store.commit('settings/fullPage', false)

  },

  methods: {
    submit () {
      this.isLoading = true

      this.$store.dispatch('passwordRecovery', {
        email: this.form.email,
      })
          .then(() => {
            this.$buefy.snackbar.open({
              message: `Password reset email succesfully sent`,
              indefinite: true,
              position: 'is-top'

            })
            this.isLoading = false

          })
          .catch(error => {
            this.isLoading = false
            this.$buefy.snackbar.open(`error`)

          })

    }
  }
}
</script>
