/* users.store.js */


const state = {
    users: [],
    companies: [],
    roles: [],
    presets: [],
    permissions: [],



}

// Getter functions
const getters = {
    getCompanyById: (state) => (id) => {
        return state.companies.find(company => company.id === id)
    }


}

// Actions
const actions = {
    fetchUsers({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/users?${payload.params}`)
                .then((response) => {
                    commit('SET_USERS', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchUserDetails({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/users/${payload.id}?${payload.params}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    inviteUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/users/invite`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchRegisterData({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/registration/${payload}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })

    },
    fetchRoles({commit}, params = "") {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/roles${params}`)
                .then((response) => {
                    commit('SET_ROLES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchPresets({commit}) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/presets`)
                .then((response) => {
                    commit('SET_PRESETS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchPermissions({commit}, params = "") {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/permissions${params}`)
                .then((response) => {
                    commit('SET_PERMISSIONS', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    registerUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/registration/${payload.token}?expires=${payload.expires}&signature=${payload.signature}`, payload.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/users/${payload.user_id}`, payload.data)
                .then((response) => {
                    //commit('UPDATE_USER', response.data.id, response.data)

                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    deleteUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/users/${payload.user_id}`)
                .then((response) => {
                    commit('DELETE_USER', payload.user_id)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    createRole({commit}, value) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/roles`, { name: value })
                .then((response) => {
                    //commit('UPDATE_USER', response.data.id, response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeRole({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/roles/${payload.role_id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateRolePermissions({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/roles/${payload.role_id}`, payload.data)
                .then((response) => {
                    //commit('UPDATE_USER', response.data.id, response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updatePresetRoles({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/presets/${payload.preset_id}`, payload.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createPreset({commit}, value) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/presets`, { name: value })
                .then((response) => {
                    //commit('UPDATE_USER', response.data.id, response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removePreset({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/presets/${payload.preset_id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },



    //COMPANIES
    fetchCompanies({commit}, params) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/companies?${params}`)
                .then((response) => {
                    commit('SET_COMPANIES', response.data.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchCompanyDetails({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/companies/${payload.id}?${payload.params}`)
                .then((response) => {
                    //commit('SET_COMPANIES', response.data.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    deleteCompany({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/companies/${payload.id}`)
                .then((response) => {
                    commit('DELETE_COMPANY', payload.id)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createCompany({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/companies`, payload)
                .then((response) => {
                    commit('ADD_COMPANY', response.data.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    userToCompany({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/companies/${payload.company_id}/users`, {
                userid: payload.user_id,
                contract: payload.contract
            })
                .then((response) => {
                    //commit('ADD_COMPANY', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

}

// Mutations
const mutations = {
    SET_USERS(state, payload) {
        state.users = payload
    },
    SET_ROLES(state, payload) {
        state.roles = payload
    },
    SET_PRESETS(state, payload) {
        state.presets = payload
    },
    SET_PERMISSIONS(state, payload) {
        state.permissions = payload
    },
    UPDATE_USER(state, id, user) {
        state.users = [
            ...state.users.filter(u => u.id !== id),
            user
        ]
    },
    SET_COMPANIES(state, payload) {
        state.companies = payload
    },
    ADD_COMPANY(state, payload) {
        state.companies.push(payload)
    },
    DELETE_COMPANY(state, id) {
        let index = state.companies.findIndex(comp => comp.id === id)
        state.companies.splice(index, 1)
    },
    DELETE_USER(state, id) {
        let index = state.users.findIndex(usr => usr.id === id)
        state.users.splice(index, 1)
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
