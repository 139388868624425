<template>
  <section class="section hero is-fullheight is-error-section">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-two-fifths">


            <card-component title="Register" icon="lock" :has-card-header-background="true">

              <form @submit.prevent="submit" method="POST">
                <b-field label="Name">
                  <b-input v-model="form.name" autofocus/>
                </b-field>
                <b-field label="E-mail Address">
                  <b-input v-model="form.email" type="email" disabled autofocus/>
                </b-field>

                <b-field label="Password">
                  <b-input v-model="form.password" type="password" name="password" minlength="8" required/>
                </b-field>
                <hr>

                <b-field grouped>
                  <div class="control">
                    <button type="submit" class="button is-black" :class="{'is-loading':isLoading}">
                      Register
                    </button>
                  </div>

                </b-field>
              </form>
            </card-component>

          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot has-text-centered">
      <div class="logo"></div>
    </div>
  </section>

</template>

<script>
import CardComponent from '@/components/CardComponent'
import auth from "@/store/modules/auth.store"
import settings from "@/store/modules/settings.store"
import users from "@/store/modules/users.store"
import store from "../../store";


export default {
  name: 'Register',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      form: {
        name: null,
        email: null,
        password: null,
      },
      token: ""
    }
  },
  created() {
    if(!auth.isRegistered) {
      this.$store.registerModule('auth', auth)
      auth.isRegistered = true
    }
    if(!users.isRegistered) {
      this.$store.registerModule('users', users)
      users.isRegistered = true
    }
    if(!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }

    this.$store.commit('settings/asideRightToggle', false)
    this.$store.commit('settings/fullPage', true)
  },
  beforeDestroy () {
    this.$store.commit('settings/fullPage', false)

  },
  mounted() {
    this.fetchRegistrationData();
  },

  methods: {

    fetchRegistrationData() {
      this.isLoading = true
      this.$store.dispatch('users/fetchRegisterData', `${this.$route.params.token}?expires=${this.$route.query.expires}&signature=${this.$route.query.signature}`)
              .then((response) => {
                this.form.email = response.data.email
                this.token = response.data.token
                this.isLoading = false
              })
              .catch(err => {
                console.log(err)
              })
    },

    submit () {
      this.isLoading = true
      let thisIns = this;
      this.$store.dispatch('users/registerUser', {
        signature: this.$route.query.signature,
        expires: this.$route.query.expires,
        token: thisIns.token,
        data: {
          email: thisIns.form.email,
          password: thisIns.form.password,
          name: thisIns.form.name,
          token: thisIns.token,
        }

      })
      .then(() => {
          store.dispatch('me').then(() => {
            this.$router.push({ name: 'home' })
            this.$buefy.snackbar.open(`Successfully logged in`)

          })
      })
      .catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
