<template>
  <section class="section hero is-fullheight is-error-section">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-two-fifths">


            <card-component title="Reset Password" icon="lock" :has-card-header-background="true">

              <form @submit.prevent="submit" method="POST">
                <b-field label="E-mail Address">
                  <b-input v-model="this.$route.query.email" type="email" disabled autofocus/>
                </b-field>

                <b-field label="New Password">
                  <b-input v-model="form.password" type="password" name="password" minlength="8" required/>
                </b-field>
                <b-field label="Confirm Password">
                  <b-input v-model="form.password_confirmation" type="password" name="password" minlength="8" required/>
                </b-field>
                <hr>

                <b-field grouped>
                  <div class="control">
                    <button type="submit" class="button is-primary" :class="{'is-loading':isLoading}">
                      Reset Password
                    </button>
                  </div>

                </b-field>
              </form>
            </card-component>

          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot has-text-centered">
      <div class="logo"></div>
    </div>
  </section>

</template>

<script>
import CardComponent from '@/components/CardComponent'
import auth from "@/store/modules/auth.store"
import settings from "@/store/modules/settings.store"
import users from "@/store/modules/users.store"
import store from "../../store";


export default {
  name: 'PasswordReset',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      form: {
        password: null,
      },
      token: ""
    }
  },
  created() {
    if(!auth.isRegistered) {
      this.$store.registerModule('auth', auth)
      auth.isRegistered = true
    }
    if(!users.isRegistered) {
      this.$store.registerModule('users', users)
      users.isRegistered = true
    }
    if(!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }

    this.$store.commit('settings/asideRightToggle', false)
    this.$store.commit('settings/fullPage', true)
  },
  beforeDestroy () {
    this.$store.commit('settings/fullPage', false)

  },
  mounted() {
  },

  methods: {


    submit () {
      this.isLoading = true
      let thisIns = this;
      this.$store.dispatch('resetPassword', {
        token: this.$route.query.token,
        email: this.$route.query.email,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,

      })
      .then(() => {
        this.$router.push('/login')
        this.$buefy.snackbar.open({
          message: `Password changed. Please log in using your new password.`,
          indefinite: true,
          position: 'is-top'
        })
      })
      .catch(err => {
        this.$buefy.snackbar.open({
          message: `Something went wrong.`,
          type: "is-danger",
          indefinite: true,
          position: 'is-top'
        })
      })
    }
  }
}
</script>
